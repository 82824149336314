import { UilPlus } from "@iconscout/react-unicons";
import { Collapse } from "@mantine/core";
import { FC, JSXElementConstructor, ReactNode, useState } from "react";

import { IndexAnchor } from "../../components/Anchor";
import { BubbleText } from "../../components/BubbleText";
import { TextLink } from "../../components/Link";
import { useIsMobile } from "../../hooks/useIsMobile";
import IconFingerprint from "../../public/webstatic/svg/icon-fingerprint.svg";
import IconGavel from "../../public/webstatic/svg/icon-gavel.svg";
import IconShield from "../../public/webstatic/svg/icon-shield.svg";
import IconUmbrella from "../../public/webstatic/svg/icon-umbrella.svg";
import PadlockImage from "../../public/webstatic/svg/lock-dotted.svg";
import ShieldImage from "../../public/webstatic/svg/shield-dotted.svg";
import { classNames } from "../../utils/classNames";
import { PoweringAngellist } from "../../components/PoweringAngellist";

const dottedUnderlineHighlight =
  "font-medium underline decoration-dotted underline-offset-4 decoration-2";
const Content = [
  {
    icon: IconFingerprint,
    title: (
      <>
        You <span className={dottedUnderlineHighlight}>always</span> have access
        to your assets
      </>
    ),
    description: (
      <>
        <p>
          Your funds are held in your name at third-party{" "}
          <TextLink
            href="https://apexfintechsolutions.com/solutions/clearing-and-custody/"
            target="_blank"
          >
            Apex Clearing
          </TextLink>
          , one of the largest US custodians holding over $114B in funds for
          prominent financial technology companies. In the unlikely event that
          something happens to Frec, your assets will be fully accessible.
        </p>
      </>
    ),
    longTitle: true,
  },
  {
    icon: IconGavel,
    title: (
      <>
        We’re SEC <span className={dottedUnderlineHighlight}>regulated</span>{" "}
        and a fiduciary
      </>
    ),
    description: (
      <>
        <p>
          Frec is a Broker Dealer and Registered Investment Advisor. We’re
          regulated by the Securities and Exchange Commission (SEC) and the
          Financial Industry Regulatory Authority (FINRA). They both have the
          rights to perform regular audits. Plus, Frec Advisers is a fiduciary,
          meaning we are legally bound to do what is in your best interest.
        </p>
      </>
    ),
    longTitle: true,
  },
  {
    icon: IconShield,
    title: (
      <>
        Our technology is{" "}
        <span className={dottedUnderlineHighlight}>robust</span>
      </>
    ),
    description: (
      <>
        <p>
          We have strict measures in place to keep your data protected.
          Sensitive information is encrypted and measures like 2FA and auto log
          out are implemented for security. We’ve instilled transactional safety
          processes, ensuring that every transaction is monitored by a
          human. Our code is written securely and is regularly audited by third
          parties.
        </p>
      </>
    ),
  },
  {
    icon: IconUmbrella,
    title: (
      <>
        Your assets are{" "}
        <span className={dottedUnderlineHighlight}>insured</span>
      </>
    ),
    description: (
      <>
        <p>
          Frec is a member of{" "}
          <TextLink href="https://www.sipc.org/" target="_blank">
            SIPC
          </TextLink>
          . Securities in your account protected up to $500,000. For details,
          please see www.sipc.org.
        </p>
        <p className="mt-4">
          On top of this,{" "}
          <TextLink
            href="https://apexfintechsolutions.com/solutions/clearing-and-custody/"
            target="_blank"
          >
            Apex Clearing
          </TextLink>{" "}
          offers excess insurance protection with an aggregate of $150M.
        </p>
      </>
    ),
  },
];

export const TrustSecurityPrivacy: FC = () => {
  return (
    <>
      <div
        className="flex flex-col items-center overflow-x-hidden md:py-24"
        id={IndexAnchor.YourMoneyIsSecure}
      >
        <div className="text-frecBeige relative flex max-w-[750px] flex-col gap-12 px-6 py-12 md:items-center md:text-center">
          <div>
            <h2>
              Your money is <br className="md:hidden" />
              <BubbleText className="text-frecBlack">secure</BubbleText>
            </h2>
            <p className="mt-2 w-44 opacity-60 md:w-auto">
              Safety & privacy are our cornerstones
            </p>
          </div>
          <div className="z-10">
            <div className="grid gap-5 md:h-full md:grid-cols-[1fr_1fr]">
              {Content.map((content, index) => (
                <Item {...content} key={index} />
              ))}
            </div>
          </div>
          <PadlockImage className="text-frecWhite absolute -right-12 top-12 md:-left-40 md:top-24" />
          <ShieldImage className="text-frecWhite absolute -bottom-12 -right-28 hidden md:block" />
          <PoweringAngellist />
        </div>
      </div>
    </>
  );
};

const Item: FC<{
  icon: JSXElementConstructor<React.SVGProps<SVGSVGElement>>;
  title: ReactNode;
  description: ReactNode;
  longTitle?: boolean;
}> = ({ icon: Icon, title, description, longTitle }) => {
  const isMobile = useIsMobile();
  const [expand, setExpand] = useState(false);
  const toggleExpand = () => setExpand((prev) => !prev);
  const expanded = expand || !isMobile;
  const ToggleIcon = expanded ? Icon : UilPlus;
  return (
    <div
      className={classNames(
        "flex flex-col items-start gap-4 rounded-2xl bg-[#262626] p-6 text-left",
        isMobile ? "cursor-pointer" : "",
      )}
      onClick={isMobile ? toggleExpand : undefined}
      role={isMobile ? "button" : ""}
    >
      <div
        className={classNames(
          "flex w-full gap-4",
          longTitle ? "items-start" : "items-center",
        )}
      >
        <div className="text-frecBeige grow text-[20px]">{title}</div>
        <ToggleIcon className="fill-frecNeon text-frecNeon h-[40px] w-[40px] shrink-0 scale-75 md:scale-100" />
      </div>
      <Collapse in={expanded}>
        <div className="text-frecBeige/80 text-[14px]">{description}</div>
      </Collapse>
    </div>
  );
};
